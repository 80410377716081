/* 主页 */
.cloud-saves-index {
    background: url('https://s3.uuu.ovh/imgs/2024/06/06/f0a48544de6d14bc.png') no-repeat center center fixed;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
}

.cloud-saves-index-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
}

.cloud-saves-index-content {
    position: absolute;
    top: 35vh;
    left: 15%;
}

.cloud-saves-index-title {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 60px;
    font-weight: 500;
    letter-spacing: .3rem;
    text-transform: uppercase;
    color: #fff;
}

.cloud-saves-index-subtitle {
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 40px;
    font-weight: 500;
    letter-spacing: .3rem;
    text-transform: uppercase;
    color: #fff;
}

.cloud-saves-index-button {
    height: 50px;
    width: 140px;
    margin-right: 20px;
    font-size: 16px;
    border: 2px solid #fff;
    border-radius: 30px;
    text-decoration: none;
    color: #fff;
    transition: all .3s;
}

/* 登录页 */
.cloud-saves-login {
    background: url('https://s3.uuu.ovh/imgs/2024/06/06/555916ed62264ac2.png') no-repeat fixed;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
}

.cloud-saves-login-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.7);
}

.cloud-saves-login-content {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    border: 1px solid #e0e0e0;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
}

.cloud-saves-login-title {
    display: flex;
    justify-content: center;
    letter-spacing: .3rem;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.88);
    font-size: 1.5em;
    font-weight: 500;
}

/* 注册页 */
.cloud-saves-register {
    background: url('https://s3.uuu.ovh/imgs/2024/06/06/555916ed62264ac2.png') no-repeat fixed;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
}

.cloud-saves-register-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.7);
}

.cloud-saves-register-content {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    border: 1px solid #e0e0e0;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
}

.cloud-saves-register-title {
    display: flex;
    justify-content: center;
    letter-spacing: .3rem;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.88);
    font-size: 1.5em;
    font-weight: 500;
}

/* 存档页 */
.Layout {
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.Layout .ant-menu {
    display: flex;
    justify-content: center;
}

.Layout-content {
    margin: 0 auto;
    margin-top: calc(15vh);
}

.ant-pro-checkcard-body {
    padding: 0 !important;
}

.inventory-list {
    width: 1200px;
    background-color: #eee;
    margin: -24px;
    padding: 24px;
}

.Menu {
    justify-content: flex-end;
}

.Upload {
    width: 1200px;
    display: flex;
    flex-flow: column;
}

.Spin {
    margin-top: 50px;
}

.ant-pro-checkcard .ant-pro-checkcard-body {
    padding: 0;
}

.actions-p {
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
}

.actions-p-1 {
    margin: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-left: -15px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #bae0ff;
    text-align: center;
    line-height: 20px
}

.actions-p-2 {
    margin: 0;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin-right: -15px
}

@media (max-width: 768px) {
    .Layout-content {
        margin: 0 auto;
        margin-top: 0;
    }

    .Upload {
        width: auto;
        display: flex;
        flex-flow: column;
        margin-top: calc(15vh);
    }

    .actions-p-1 {
        margin: 0;
    }

    .actions-p-2 {
        margin: 0;
    }

    .inventory-list {
        width: auto;
        margin: 0;
        padding: 0;
    }

    .inventory-list-tag {
        display: none;
    }

    .ant-list-item-meta-title {
        width: 70px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 576px) {
    .cloud-saves-index-content {
        position: absolute;
        top: 25vh ;
        left: 15%;
    }

    .cloud-saves-index-title {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 50px;
        font-weight: 500;
        letter-spacing: .3rem;
        text-transform: uppercase;
        color: #fff;
    }
    
    .cloud-saves-index-subtitle {
        font-size: 30px;
        margin-top: 0;
        margin-bottom: 20px;
        font-weight: 500;
        letter-spacing: .3rem;
        text-transform: uppercase;
        color: #fff;
    }

    .cloud-saves-index-button {
        height: 40px;
        width: 120px;
        margin-right: 10px;
        border: 2px solid #fff;
        border-radius: 30px;
        text-decoration: none;
        color: #fff;
        transition: all .3s;
    }

}

@media (max-width: 360px) {
    .cloud-saves-index-content {
        position: absolute;
        top: 15vh ;
        left: 15%;
    }

    .cloud-saves-index-title {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 50px;
        font-weight: 500;
        letter-spacing: .3rem;
        text-transform: uppercase;
        color: #fff;
    }
    
    .cloud-saves-index-subtitle {
        font-size: 30px;
        margin-top: 0;
        margin-bottom: 20px;
        font-weight: 500;
        letter-spacing: .3rem;
        text-transform: uppercase;
        color: #fff;
    }

    .cloud-saves-index-button {
        height: 40px;
        width: 120px;
        margin-right: 10px;
        border: 2px solid #fff;
        border-radius: 30px;
        text-decoration: none;
        color: #fff;
        transition: all .3s;
    }

    .ant-list-item-meta-title {
        width: 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}